<template>
  <div>
    <b-table :fields="fields"
             :items="[proposalWithSamplesData]"
             bordered
             class="table-responsive"
             head-variant="light"
             responsive
             stacked
             striped>
      <template v-slot:cell(feasibility)="row">
        {{
          row.item.technical_evaluation.feasibility ?
            options.find(x => x.value === row.item.technical_evaluation.feasibility).text : ''
        }}
      </template>
      <template v-slot:cell(actions)="row">
        <action-download-pdf :proposal="row.item"/>
      </template>
    </b-table>
    <technical-form v-model="proposal.technical_evaluation"
                    :document_id="proposal._id['$oid']"
                    :isEditable="isEditable"
                    @closeModal="close_modal_emit"/>
  </div>
</template>

<script>
  import ActionDownloadPdf from '@/components/actions/ActionDownloadPdf';
  import technicalForm from '@/components/evaluation/technical/technical_form';
  import options from '@/json/feasibility';

  export default {
    components: {
      ActionDownloadPdf,
      technicalForm,
    },
    props: {
      value: '',
      fields: {
        type: Array,
        default: () => [],
      },
      isEditable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        proposal: this.value,
        proposalWithSamplesData: JSON.parse(JSON.stringify(this.value)),
        options: options.options,
      };
    },
    watch: {
      value() {
        this.proposal = this.value;
        if (this.proposal.technical_evaluation === undefined) {
          this.proposal.technical_evaluation = {};
        }
      },
      proposal: {
        handler() {
          this.$emit('input', this.proposal);
        },
        deep: true,
      },
    },
    created() {
      this.updateProposalWithSamplesData();
    },
    methods: {
      updateProposalWithGivenKey(keyName) {
        const { samples } = this.proposalWithSamplesData;
        this.proposalWithSamplesData[keyName] = samples.map((sample) => sample[keyName]).join(' | ');
      },
      updateProposalWithSamplesData() {
        const keysToUpdate = [
          'name',
          'type',
          'grid_type',
          'molecular_data',
          'number_of_grids',
          'buffer_system',
          'concentration',
          'biosafety',
          'other_risk',
          'gmm_gmo',
        ];
        keysToUpdate.forEach((key) => this.updateProposalWithGivenKey(key));
      },
    },
  };
</script>

<style>

</style>
