<template>
  <div>
    <p class="card-text">
      <b-form-group label="Feasibility:"
                    label-for="feasibility">
        <b-form-select id="feasibility"
                       v-model="technical_evaluation.feasibility"
                       :disabled="!isEditable"
                       :options="options">
        </b-form-select>
      </b-form-group>
      <b-form-group label="Comments visible for Reviewers, Management, Safety & Radiology inspectors:"
                    label-for="comments">
        <b-form-textarea id="comments"
                         v-model="technical_evaluation.comments"
                         :readonly="!isEditable"
                         rows="3">
        </b-form-textarea>
      </b-form-group>
      <b-form-group label="Comments FOR USER (also visible to Reviewers and Management):"
                    label-for="comments for user">
        <b-form-textarea id="comments for user"
                         v-model="technical_evaluation.comments_for_user"
                         :readonly="!isEditable"
                         rows="3">
        </b-form-textarea>
      </b-form-group>
    </p>
    <b-button v-if="isEditable"
              class="ma-1 right"
              variant="info"
              @click="save_evaluation">SAVE
    </b-button>
  </div>
</template>

<script>
  import { envMixin } from '@/mixins/environment';
  import options from '@/json/feasibility';

  export default {
    props: ['document_id', 'value', 'isEditable'],
    mixins: [envMixin],
    data() {
      return {
        technical_evaluation: JSON.parse(JSON.stringify(this.value)),
        options: options.options,
      };
    },
    methods: {
      isTechnicalEvaluationSubmittable() {
        return this.technical_evaluation?.feasibility !== 'UNFEASIBLE'
          || (this.technical_evaluation.comments && this.technical_evaluation.comments_for_user);
      },
      save_evaluation() {
        this.axios.post(`/documents/${this.environment}/${this.document_id}/technical`, this.technical_evaluation)
          .then((response) => {
            if (response.data) {
              this.technical_evaluation = response.data.technical_evaluation;
              this.$emit('input', this.technical_evaluation);
              this.$notify({ type: 'success', title: 'Saved successfully!' });
              if (!this.isTechnicalEvaluationSubmittable()) {
                this.$notify({
                  type: 'warn',
                  title: 'For \'UNFEASIBLE\' evaluation comments are obligatory',
                });
              }
              this.close_modal_emit();
            } else {
              this.$notify({ type: 'error', title: 'Saving error!' });
            }
          })
          .catch((error) => {
            if (error.response.data.error) {
              this.$notify({ type: 'error', title: error.response.data.error });
            } else {
              this.$notify({ type: 'error', title: 'Communication error!' });
            }
          });
      },
    },
    watch: {
      value: {
        handler() {
          this.technical_evaluation = JSON.parse(JSON.stringify(this.value));
          if (this.technical_evaluation === undefined) {
            this.technical_evaluation = {};
          }
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss"
       scoped>
</style>
